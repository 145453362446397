import React, { useContext, useEffect, useState } from "react";

const NoPhoto = () => {
  return (
    <div
      style={{
        color: "white",
        backgroundColor: "black",
        width: "100px",
        height: "100px",
        fontSize: "25px",
        textAlign: "center",
        paddingTop: "15px",
      }}
    >
      No Photo
    </div>
  );
};

export default NoPhoto;
