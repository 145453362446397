import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

let ModalWrapper = (props) => {
  //  const [show, setShow] = useState(props.show);

  // if (show !== props.show) setShow(props.show);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  // const handleOnClickClose = () => {
  //   handleClose();
  // };

  const handleSave = (event) => {
    if (props.handleOnClick(event)) {
      // handleClose();
      props.closeModal();
    }
  };

  const renderModal = () => {
    return (
      <Modal show={props.show} backdrop={"static"}>
        <Modal.Header>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.children}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.closeModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <React.Fragment>
      {/* <Button variant="primary" onClick={handleShow}>
        {props.title}
      </Button> */}
      {props.show ? renderModal() : null}
    </React.Fragment>
  );
};

export default ModalWrapper;
