import React, { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../../store";

import ReactDOM from "react-dom";
import Modal from "react-modal";
import Image from "react-bootstrap/Image";
import {
  SaveProductWithUpload,
  DeleteImage,
  GetCategories,
} from "../../../actions";
import Dropdown from "react-dropdown";
import ImageModal from "./ImageModal";
import {
  faPlusCircle,
  faMinusCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const qs = require("query-string");
//test
const labelStyle = {
  width: "100px",
  textAlign: "right",
  margin: "10px",
};

const tagListStyleNormal = "list-group-item list-group-item-primary text-left";
const tagListStyleSelected = "list-group-item list-group-item-danger text-left";

const inputStyle = {
  width: "300px",
  textAlign: "right",
  margin: "10px",
  // border: ".5px solid red",
};

const inputRowStyle = {
  // border: "1px solid blue",
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "center",
};

const customStyles = {
  content: {
    width: "90%",
    height: "90%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "scroll",
    backgroundColor: "#424756",
  },
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

let UploadModal = (props) => {
  //const parsed = qs.parse(window.location);
  //allow user to create their own categories and store in server

  useEffect(() => {
    if (Categories.length == 0) GetCategories(dispatch);
  }, []);

  const conditions = ["New", "Used"];
  // const categories = [
  //   { id: 1, label: "Other" },
  //   { id: 2, label: "Household" },
  //   { id: 3, label: "Handmade" },
  //   { id: 4, label: "Electronic" },
  //   { id: 5, label: "Clothes And Accessories" },
  // ];
  const {
    dispatch,
    AuthToken,
    BaseURL,
    AccountID,
    Categories,
    ImageLocation,
  } = useContext(StoreContext);

  const [modalIsOpen, setIsOpen] = useState(false);

  const [title, setTitle] = useState("");
  const [tags, setTags] = useState([]);
  const [tag, setTag] = useState("");
  const [images, setImages] = useState([]);
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [cost, setCost] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [condition, setCondition] = useState(conditions[0]);
  const [categoryID, setCategoryID] = useState(0);

  const [categoryName, setCategoryName] = useState("");
  const [sortingIndex, setSortingIndex] = useState(0);
  const [tagListStyle, setTagListStyle] = useState(tagListStyleNormal);

  const [fileData, setFileData] = useState();
  const [files, setFiles] = useState();
  const [bird, setBird] = useState();
  const categories = useState(Categories);
  useEffect(() => {
    if (modalIsOpen && props.Product != undefined) {
      setTitle(props.Product.ListingData.Title);
      setDescription(props.Product.ListingData.Description);
      setFiles(props.Product.ListingData.Images);
      const s = props.Product.ListingData.Condition;

      setImages(props.Product.ListingData.Images);
      setCondition(conditions.find((c) => c == s));
      setPrice(props.Product.ListingData.Price);
      setCost(props.Product.ListingData.Cost);
      setQuantity(props.Product.ListingData.Quantity);
      setSortingIndex(props.Product.ListingData.SortingIndex);
      setCategoryID(props.Product.ListingData.CategoryID);
      setCategoryName(
        Categories.find(
          (c) => c.CategoryID == props.Product.ListingData.CategoryID
        )?.CategoryName
      );
    }
  }, [modalIsOpen]);

  let subtitle;

  function openModal() {
    setIsOpen(true);
  }

  function handleClose() {
    setIsOpen(false);
  }

  const ReadFile = (e) => {
    e.preventDefault();
    const files = e.target.files;
    setFileData(files);
  };

  const onFileChangeHandler = (e) => {
    e.preventDefault();

    setFileData(e.target.files);
  };

  let handleSave = () => {
    if (!ValidateForm()) return;
    let product = {
      ListingID: props.Product.ListingID,
      ListingData: {
        ListingID: props.Product.ListingID,
        Title: title,
        Description: description,
        Cost: cost,
        Price: price,
        Quantity: quantity,
        Active: true,
        Images: images,
        CategoryID: categoryID,
        CategoryName: categoryName,
        Condition: condition,
      },
      AccountID: props.Product.AccountID,
      CreatedDate: props.Product.CreatedDate,
      ModifiedDate: props.Product.ModifiedDate,
    };

    //if (FormData.GUID == "") {
    SaveProductWithUpload(product, fileData, dispatch);
    //} else console.log("perform edit");

    handleClose();
  };

  const buildProductObject = {
    ListingID: 1,
    ListingData: {
      ListingID: 100,
      Title: "MyTitle",
      Description: "MyDescription",
      Cost: 50,
      Price: 100,
      Quantity: 5,
      Active: true,
      Images: ["24b4e449-4b6a-4c17-ac27-b29e8e256843farmAd (3).png"],
    },
    AccountID: "accountID",
    CreatedDate: "2020-09-18T01:53:37.12",
    ModifiedDate: null,
  };

  const handleDeleteFile = (fname) => {
    handleClose();
    //DeleteImage(dispatch, fname);
    // setFiles(files.filter((item) => item != fname));
    var filtered = images.filter((item) => item != fname);

    var accountInfoProduct = buildProductObject;
    accountInfoProduct.Product.ListingData.Images = filtered;

    setImages(filtered);
    DeleteImage(dispatch, accountInfoProduct);
  };

  useEffect(() => {
    setFiles(files);
  }, [files]);

  const renderFiles = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {images
          //.filter((item) => item.includes("jpg"))
          ?.map((item) => {
            return (
              <div style={{ display: "flex" }}>
                <div style={{ color: "#675645" }}>
                  <FontAwesomeIcon
                    icon={faTrash}
                    color="Tomato"
                    size="lg"
                    onClick={() => setImages(images.filter((i) => i != item))}
                  />
                </div>
                <div>
                  <Image
                    src={ImageLocation + item}
                    style={{
                      width: "auto",
                      height: "100px",
                      marginLeft: "10px",
                    }}
                  />
                </div>
              </div>
            );
          })}
      </div>
    );
  };
  const ValidateForm = () => {
    let isValid = true;

    return isValid;
  };

  const addTag = (tag) => {
    let x = tags;
    x.push(tag);
    setTags(x);
    setTag("");
  };

  const removeTag = (tag) => {
    let x = tags.filter((item) => item !== tag);
    renderTags();
    setTags(x);
    setTag("");
    setTagListStyle(tagListStyleNormal);
  };

  const selectTagFromList = (e) => {
    setTag(e.target.innerText);
    setTagListStyle(tagListStyleNormal);
    e.target.className = tagListStyleSelected;
    //e.target.style.color = "red";
    //setTagListStyle(tagListStyleSelected);
  };

  const getTagListStyle = () => {
    return tagListStyle;
  };

  const renderTags = () => {
    let listStyle = "list-group-item list-group-item-primary text-left";
    return tags.map((item) => {
      if (item == tag)
        listStyle = "list-group-item list-group-item-danger text-left";
      else listStyle = "list-group-item list-group-item-primary text-left";
      return (
        <li className={listStyle} onClick={(e) => selectTagFromList(e)}>
          {item}
        </li>
      );
    });
    // tags.map((item) => {
    //   if (item == tag) {
    //     return (
    //       <li
    //         className="list-group-item list-group-item-danger text-left"
    //         onClick={(e) => selectTagFromList(e)}
    //       >
    //         {item}
    //       </li>
    //     );
    //   } else {
    //     return (
    //       <li
    //         className="list-group-item list-group-item-primary text-left"
    //         onClick={(e) => selectTagFromList(e)}
    //       >
    //         {item}
    //       </li>
    //     );
    //   }
    // });
  };
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <div style={{ padding: "10px" }}>
      <div onClick={openModal}>{props.children}</div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>{props.Alt}</h2>
        <div
          style={{
            border: ".1px solid gray",
            borderRadius: "10px",
            // color: "dodgerblue",
            display: "flex",
            width: "100%",
            flexWrap: "wrap",
            padding: "20px",
            backgroundColor: "#424756",
            color: "#e1d798",
          }}
        >
          <div style={inputRowStyle}>
            <div style={labelStyle}>
              <label>Category:</label>
            </div>
            <div style={inputStyle}>
              <div className="dropdown">
                <button
                  className="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenu2"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {categoryName}
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                  {Categories.map((item) => {
                    return (
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => {
                          setCategoryID(item.CategoryID);
                          setCategoryName(item.CategoryName);
                        }}
                      >
                        {item.CategoryName}
                      </button>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <div style={inputRowStyle}>
            <div style={labelStyle}>
              <label>Title:</label>
            </div>
            <div style={inputStyle}>
              <input
                className="form-control"
                type="text"
                style={{ width: "100%" }}
                placeholder="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              ></input>
            </div>
          </div>

          <div style={inputRowStyle}>
            <div style={labelStyle}>
              <label>Description:</label>
            </div>
            <div style={inputStyle}>
              <textarea
                className="form-control"
                style={{ width: "100%" }}
                rows={5}
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>
          </div>
          <div style={inputRowStyle}>
            <div style={labelStyle}>
              <label>Cost:</label>
            </div>
            <div style={inputStyle}>
              <input
                className="form-control"
                type="text"
                style={{ width: "100%" }}
                placeholder="Cost"
                value={cost}
                onChange={(e) => setCost(e.target.value)}
              ></input>
            </div>
          </div>
          <div style={inputRowStyle}>
            <div style={labelStyle}>
              <label>Price:</label>
            </div>
            <div style={inputStyle}>
              <input
                className="form-control"
                type="text"
                style={{ width: "100%" }}
                placeholder="Selling Price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              ></input>
            </div>
          </div>
          <div style={inputRowStyle}>
            <div style={labelStyle}>
              <label>Quantity:</label>
            </div>
            <div style={inputStyle}>
              <input
                className="form-control"
                type="number"
                style={{ width: "100%" }}
                placeholder="Enter Quantity"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
              ></input>
            </div>
          </div>
          <div style={inputRowStyle}>
            <div style={labelStyle}>
              <label>Condition:</label>
            </div>
            <div style={inputStyle}>
              <div className="dropdown">
                <button
                  className="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenu2"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {condition}
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                  {conditions.map((item) => {
                    return (
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => setCondition(item)}
                      >
                        {item}
                      </button>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div style={inputRowStyle}>
            <div style={labelStyle}>
              <label>Tags:</label>
            </div>
            <div style={inputStyle}>
              <input
                className="form-control"
                type="text"
                style={{ width: "100%" }}
                placeholder="Tag"
                value={tag}
                onChange={(e) => setTag(e.target.value)}
              ></input>

              <FontAwesomeIcon
                icon={faPlusCircle}
                color="#6ea171"
                size="lg"
                onClick={() => addTag(tag)}
              />
              {"  "}
              <FontAwesomeIcon
                icon={faMinusCircle}
                color="Tomato"
                size="lg"
                onClick={() => removeTag(tag)}
              />

              <br />
              <ul class="list-group">{renderTags()}</ul>
            </div>
          </div>
          <div style={inputRowStyle}>
            <div style={labelStyle}>
              <label>Sort Index:</label>
            </div>
            <div style={inputStyle}>
              <input
                className="form-control"
                type="text"
                style={{ width: "100%" }}
                placeholder="Sort Index"
                value={sortingIndex}
                onChange={(e) => setSortingIndex(e.target.value)}
              ></input>
            </div>
          </div>
          <div style={inputRowStyle}>
            <div style={labelStyle}>
              <label>Files:</label>
            </div>
            <div style={inputStyle}>{renderFiles()}</div>
          </div>
          <div style={inputRowStyle}>
            <label>Choose Files or Drag Files Below</label>
          </div>
          <div style={inputRowStyle}>
            <input
              type="file"
              className="form-control"
              name="file"
              multiple
              onChange={(sender) => ReadFile(sender)}
              style={{ height: "50px" }}
            />
          </div>
        </div>

        <div style={{ display: "flex", margin: "10px" }}>
          <div style={{ marginRight: "5px" }}>
            <input
              style={{ border: "1px solid black" }}
              type="button"
              onClick={() => handleClose()}
              value="Close"
              className="btn btn-info"
            />
          </div>

          <div>
            <input
              style={{ border: "1px solid black" }}
              type="button"
              className="btn btn-info"
              onClick={() => handleSave()}
              value="Save"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default UploadModal;
