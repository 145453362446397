import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import { DeleteProduct } from "../../../actions";
import { StoreContext } from "../../../store";

const DeleteConfirmation = (props) => {
  const { dispatch, AuthToken, Products } = useContext(StoreContext);
  const product = props.Product;
  let subtitle;

  const [modalIsOpen, setIsOpen] = useState(false);
  const [cancelStyle, setCancelStyle] = useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  function handleClose() {
    setIsOpen(false);
  }

  //duplicated code

  const customStyles = {
    content: {
      width: "300px",
      height: "300px",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#675645",
      color: "#e1d798",
    },
  };

  const handleDelete = () => {
    closeModal();

    //pass accountinfoproduct

    DeleteProduct(dispatch, props.Product.ListingID, AuthToken);
  };
  return (
    <React.Fragment>
      <div style={{ padding: "10px" }}>
        <div onClick={openModal}>{props.children}</div>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <h2 ref={(_subtitle) => (subtitle = _subtitle)}>{props.Alt}</h2>

          <div>
            <div>
              {<label>{props.Msg}</label>}
              <br></br> <br></br>
              <span
                style={{ cursor: "pointer", color: "#6ea171" }}
                onClick={() => closeModal()}
              >
                Cancel
              </span>
              <br></br> <br></br>
              <span
                style={{ cursor: "pointer", color: "Tomato" }}
                onClick={() => handleDelete()}
              >
                Yes, I'm sure!
              </span>
            </div>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default DeleteConfirmation;
