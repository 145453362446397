import React, { useState, useContext, useEffect } from "react";
import { StoreContext } from "../store";
import "../App.css";
import AdminCards from "../components/atoms/molecules/AdminCards";
import Login from "./atoms/molecules/Login";

let Admin = () => {
  const { IsLogin, UserName } = useContext(StoreContext);

  const renderLoggedin = () => {
    return (
      <div>
        <h3>Welcome {UserName}! You're logged in. </h3>
        <br></br>
        You may now add,edit, and delete from your bird catalog. Oh, and don't
        forget to logout (top right of your screen) when you're done.
      </div>
    );
  };
  return (
    <div className="admin-container">
      <div>{IsLogin ? renderLoggedin() : <Login></Login>}</div>
    </div>
  );
};
export default Admin;
