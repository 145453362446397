import React, { useState, useContext, useEffect } from "react";

import { Dropdown } from "react-bootstrap";
import { UpdateShoppingcartItem } from "../../../actions";
import { StoreContext } from "../../../store";

const DropDown = (props) => {
const label = props.Label;
const action = props.Action;
/*
expected input
const options = ['A-Z', 'Z-A','Sex Male-Femaile', 'Sex Female-Male','Age Young- Old', 'Age Old-Young']

handleOnClick

*/
//test
 // const options = ['A-Z', 'Z-A','Sex Male-Femaile', 'Sex Female-Male','Age Young- Old', 'Age Old-Young'];
  const options =  props.Options;
  const [selected, setSelected] = useState(options[0]);


  
  //const _selected = props.Selected;

  // useEffect(() => {
  //   setSelected(props.Selected);
  // }, [props.Selected]);
  //if (_selected > 0) setSelected(_selected);

 
  const handleOnClick = (selectedItem) => {
      setSelected(selectedItem);
      action(selectedItem);
  };


  return (
    <div style={{ display:'flex', width:'150px', alignItems:'baseline', justifyContent:'space-around'}}>
    <div>{label}</div>
    <div className="dropdown">
      <button
        className="btn btn-secondary dropdown-toggle"
        type="button"
        id="dropdownMenu2"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        style={{
          minWidth: "50px",
          textAlign: "right",
          backgroundColor: "#4d784e",
        }}
      >
        {selected}
      </button>
      <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
        {options.map((item) => {
          return (
            <button
              className="dropdown-item"
              type="button"
              onClick={() => handleOnClick(item)}
            >
              {item}
            </button>
          );
        })}
      </div>
    </div>
    </div>
  );
};
export default DropDown;
