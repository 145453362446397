import React, { useState, useContext, useEffect } from "react";
import { StoreContext } from "../../../store";

import ReactDOM from "react-dom";
import Modal from "react-modal";
import Image from "react-bootstrap/Image";
//test
const customStyles = {
  content: {
    width: "90%",
    height: "90%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "scroll",
  },
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

let ImageModal = (props) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const { BaseURL } = useContext(StoreContext);
  let subtitle;
  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }
  //const baseUrl = "http://api.pigeonconnect.com/api/";
  //const baseUrl = "http://localhost:64567/api/";
  // const imageURL = require("../../../birds/imports/" + props.ImageURL);
  const imageURL =
    BaseURL + "/UploadedFiles/wingandaprayerloft/Catalog/" + props.ImageURL;

  return (
    <div>
      <span onClick={openModal} className="catalog-link">
        {props.Text}
      </span>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>{props.Alt}</h2>
        <div style={{ padding: "10px" }}>
          <button onClick={closeModal}>Go Back</button>
        </div>
        <Image
          src={imageURL}
          alt={props.Alt}
          style={{ width: "700px", height: "auto" }}
        />
        <div className="description-expanded">{props.Description}</div>
      </Modal>
    </div>
  );
};

export default ImageModal;
