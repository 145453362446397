import React, { useState, useContext, useEffect } from "react";

import { StoreContext } from "../store";

import Image from "react-bootstrap/Image";
import ImageModal from "./atoms/molecules/ImageModal";
//import Filter from "./atoms/molecules/filter";
import ContactUsModal from "./atoms/molecules/ContactUsModal";
import UploadModal from "./atoms/molecules/UploadModal";
import DeleteConfirmation from "./atoms/molecules/DeleteConfirmation";
import { GetProducts } from "../actions";
import ProductDetail from "./atoms/ProductDetail";
import ProductCardAdmin from "./atoms/ProductCardAdmin";

let ProductsAdmin = (props) => {
  //const baseUrl = "http://localhost:64567";
  const {
    dispatch,
    AuthToken,
    Products,
    IsLogin,
    BaseURL,
    NewProduct,
  } = useContext(StoreContext);
  //const [filter, setFilter] = useState("All");
  const [showContactModal, setShowContactModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [products, setProducts] = useState();
  const [showDetail, setShowDetail] = useState();
  const [productDetail, setProductDetail] = useState(null);

  //next time define all the props here so if object changes it only needs to be updated here

  useEffect(() => {
    // console.log(JSON.stringify(Products));
    if (Products.length == 0) GetProducts(dispatch);
  }, []);

  function getRootUrl() {
    var defaultPorts = { "http:": 80, "https:": 443 };

    return (
      window.location.protocol +
      "//" +
      window.location.hostname +
      (window.location.port &&
      window.location.port != defaultPorts[window.location.protocol]
        ? ":" + window.location.port
        : "")
    );
  }

  const currency = "$";

  const hideModalClick = () => {
    setShowContactModal(false);
  };

  const handleShowContact = () => {
    setShowContactModal(true);
  };

  const renderAdmin = (product) => {
    return (
      <React.Fragment>
        {" "}
        <div className="col-item">
          <h1>Products Admin</h1>
        </div>
      </React.Fragment>
    );
  };

  const handleProductClick = (product) => {
    setShowDetail(!showDetail);
    setProductDetail(product);
  };

  const handleAddEditClick = (product) => {
    alert("handleAddEditClick");
    renderAdmin(product);
  };

  const renderItems = () => {
    return (
      <React.Fragment>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {Products?.length > 0 ? (
            Products.sort(
              (a, b) =>
                b.SortingIndex - a.SortingIndex ||
                new Date(b.CreatedDate) - new Date(a.CreatedDate)
            )?.map((filteredProduct, index) => (
              <ProductCardAdmin
                key={filteredProduct.ListingID}
                Product={filteredProduct}
                HandleClick={handleProductClick}
                HandleAddEdit={handleAddEditClick}
                Count={index}
              ></ProductCardAdmin>
            ))
          ) : (
            <span style={{ color: "red", fontWeight: "bolder" }}>
              No Result
            </span>
          )}
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
        {IsLogin ? (
          <div style={{ textAlign: "left" }}>
            <UploadModal Product={NewProduct}>
              <div
                style={{
                  textAlign: "center",
                  color: "white",
                  backgroundColor: "#4b5320",
                  padding: "15px",
                  borderRadius: "10px",
                  width: "150px",
                  margin: "5px",
                }}
              >
                Add Item
              </div>
            </UploadModal>{" "}
          </div>
        ) : null}
      </div>
      <div
        style={{
          flexGrow: 2,
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
          marginBottom: "10px",
          flexWrap: "wrap",
          // borderTop: "1px solid gray",
          // borderRadius: "10px",
        }}
      >
        {productDetail == null ? (
          renderItems()
        ) : (
          <ProductDetail Product={productDetail}>
            <div style={{ textAlign: "left" }}>
              {/* <UploadBirdModal Text="Edit Bird" Bird={bird}></UploadBirdModal>{" "} */}
              <h1>hey</h1>
            </div>
          </ProductDetail>
        )}
      </div>
    </React.Fragment>
  );
};

export default ProductsAdmin;
