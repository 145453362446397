export const ActionTypes = {
  LOAD_PRODUCTS: "LOAD_PRODUCTS",
  LOAD_CATEGORIES: "LOAD_CATEGORIES",
  UPDATE_SHOPPINGCART: "SHOPPINGCART",
  UPDATE_LOGIN: "UPDATE_LOGIN",
  UPDATE_AUTH_TOKEN: "UPDATE_AUTH_TOKEN",
  UPDATE_USERNAME: "UPDATE_USERNAME",
  ADD_TO_CART: "ADD_TO_CART",
  UPDATE_PRODUCT_QUANTITY: "UPDATE_PRODUCT_QUANTITY",
  LOAD_SHOPPINGCART: "LOAD_SHOPPINGCART",
  UPDATE_SHOPPINGCART_ITEM: "UPDATE_SHOPPINGCART_ITEM",
};

export default ActionTypes;
